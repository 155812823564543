html{
  font-size: 62.5%;
  font-family: "NotoSans","游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 400;
  text-size-adjust: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #ffffff;
  word-wrap: break-word;
  line-height: 1.5;
  color:#0e1b25;
}

* {
  box-sizing: border-box;
}


img{
  width:100%;
  height: auto;
  vertical-align: top;
}

a {
  text-decoration: none;
  text-decoration-skip-ink: none;
  color: #000;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

@font-face{
  font-family:'NotoSans';//[M]
  font-style: normal;
  font-weight: 400;
  src:url('/assets/font/NotoSansJP-Regular.woff2') format('woff2'),
  url('/assets/font/NotoSansJP-Regular.woff') format('woff');
}

@font-face{
  font-family:'NotoSans';//[B]
  font-style: normal;
  font-weight: 500;
  src:url('/assets/font/NotoSansJP-Medium.woff2') format('woff2'),
  url('/assets/font/NotoSansJP-Medium.woff') format('woff');
}

@font-face{
  font-family:'NotoSans';//[E]
  font-style: normal;
  font-weight: 700;
  src:url('/assets/font/NotoSansJP-Bold.woff2') format('woff2'),
  url('/assets/font/NotoSansJP-Bold.woff') format('woff');
}

@font-face{
  font-family:'NotoSans';//[H]
  font-style: normal;
  font-weight: 900;
  src:url('/assets/font/NotoSansJP-Black.woff2') format('woff2'),
  url('/assets/font/NotoSansJP-Black.woff') format('woff');
}


@font-face{
  font-family:'NotoSerif';
  font-style: normal;
  font-weight: 500;
  src:url('/assets/font/NotoSerifJP-Medium.woff2') format('woff2'),
  url('/assets/font/NotoSerifJP-Medium.woff') format('woff');
}

@font-face{
  font-family:'NotoSerif';
  font-style: normal;
  font-weight: 600;
  src:url('/assets/font/NotoSerifJP-SemiBold.woff2') format('woff2'),
  url('/assets/font/NotoSerifJP-SemiBold.woff') format('woff');
}

@font-face{
  font-family:'PressStart2P';
  font-style: normal;
  font-weight: 400;
  src:url('/assets/font/PressStart2P-Regular.ttf') format('truetype');
}


//top-canvas用
@font-face{
  font-family:'NotoSansBlack';//[H]
  font-style: normal;
  font-weight: 900;
  src:url('/assets/font/NotoSansJP-Black.woff2') format('woff2'),
  url('/assets/font/NotoSansJP-Black.woff') format('woff');
}

@font-face{
  font-family:'NotoSerifMedium';
  font-style: normal;
  font-weight: 500;
  src:url('/assets/font/NotoSerifJP-Medium.woff2') format('woff2'),
  url('/assets/font/NotoSerifJP-Medium.woff') format('woff');
}

