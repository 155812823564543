//// ==========================================================================
//// foundation
//// ==========================================================================
@import "_foundation/_reset";
@import "_foundation/_variable";
@import "_foundation/_base";

//// ==========================================================================
//// structure
//// ==========================================================================
//@import "_structure/_menu";
//@import "_structure/_footer";


//// ==========================================================================
//// component
//// ==========================================================================


//// ==========================================================================
//// lib
//// ==========================================================================


//// ==========================================================================
//// page
//// ==========================================================================


.sp {
  @include mq-pc {
    display:none;
  }
}

.pc {
  @include mq-sp {
    display:none;
  }
}

body{
  width:100%;
  min-width: $minW;
  @include mq-sp{
    min-width: 100%;
  }
}


div#wrapper {
  min-width: $minW;
  position:relative;
  @include mq-sp {
    min-width: 100%;
    overflow: hidden;
  }
}

//.main{
//  @include mq-sp{
//    margin-top: pxToVw(120px);
//  }
//}

/*****************************************************************************************************************
 * パンくずリスト
 *****************************************************************************************************************/

//.breadcrumb-wrap{
//  position: absolute;
//  top:46px;
//  left:459px;
//  @include mq-sp{
//    top:pxToVw(8px);
//    left:pxToVw(50px);
//  }
//}
//
//.breadcrumb{
//  display: flex;
//  flex-direction:row;
//  flex-wrap: wrap;
//  li{
//    font-size: 14px;
//    font-family: "Noto-Sans-Light";
//    color:#fff;
//    letter-spacing: 0.13em;
//    @include mq-sp{
//      font-size: pxToVw(21px);
//    }
//    &::after{
//      content:">";
//      display: inline-block;
//      @include mq-sp{
//      }
//    }
//    &:last-of-type{
//      padding-left: 8px;
//      @include mq-sp{
//        padding-left: pxToVw(13px);
//      }
//      &::after{
//        display: none;
//      }
//    }
//    a{
//      display: inline-block;
//      color:#fff;
//      padding:0 8px;
//      // transition: opacity .2s linear;
//      @include hoverOpacity;
//      @include mq-sp{
//        padding:0 pxToVw(9px);
//      }
//      // &:hover{
//      // 	opacity: 0.8;
//      // }
//    }
//  }
//}

