//ブラウザ最小サイズ
$minW: 1000px;
//break point
$BreakPoint-MaxSP: 768px;

@mixin font-Yumincho {
  font-family: "Yu Mincho", "YuMincho", serif;
}


@mixin mq-sp {
  @media screen and (max-width: $BreakPoint-MaxSP) {
    @content;
  }
}

@mixin mq-pc {
  @media screen and (min-width: $BreakPoint-MaxSP + 1) {
    @content;
  }
}

@mixin hoverOpacity(){
  transition: opacity .3s cubic-bezier(0.33, 1, 0.68, 1);
  &:hover{
    opacity: 0.6;
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function pxToVw($pxValue) {
  @return ((strip-unit($pxValue) / 1125) * 100) + vw;
}

@mixin img-bg($url, $background-width, $background-height: auto, $width: 100%, $height: "") {

  @if ($height == "") {
    $height: $background-height;
  }

  background-image: url($url);
  @if ($background-width != $background-height) {
    background-size: $background-width $background-height;
  }@else{
    background-size: $background-width;
  }
  background-repeat: no-repeat;
  background-position: center top;
  width: $width;
  height: $height;
  display: block;
  position: relative;

}


@mixin hide-text() {
  $width: -9999px;
  overflow: hidden;
  white-space: nowrap;
  text-indent: $width;
}
