@charset "UTF-8";
/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
html {
  color: #000;
  background: #FFF; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ol, ul {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0;
  font-variant: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%; }

legend {
  color: #000; }

#yui3-css-stamp.cssreset {
  display: none; }

html {
  font-size: 62.5%;
  font-family: "NotoSans","游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 400;
  text-size-adjust: 100%;
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #ffffff;
  word-wrap: break-word;
  line-height: 1.5;
  color: #0e1b25; }

* {
  box-sizing: border-box; }

img {
  width: 100%;
  height: auto;
  vertical-align: top; }

a {
  text-decoration: none;
  text-decoration-skip-ink: none;
  color: #000; }

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/NotoSansJP-Regular.woff2") format("woff2"), url("/assets/font/NotoSansJP-Regular.woff") format("woff"); }

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/font/NotoSansJP-Medium.woff2") format("woff2"), url("/assets/font/NotoSansJP-Medium.woff") format("woff"); }

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 700;
  src: url("/assets/font/NotoSansJP-Bold.woff2") format("woff2"), url("/assets/font/NotoSansJP-Bold.woff") format("woff"); }

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 900;
  src: url("/assets/font/NotoSansJP-Black.woff2") format("woff2"), url("/assets/font/NotoSansJP-Black.woff") format("woff"); }

@font-face {
  font-family: 'NotoSerif';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/font/NotoSerifJP-Medium.woff2") format("woff2"), url("/assets/font/NotoSerifJP-Medium.woff") format("woff"); }

@font-face {
  font-family: 'NotoSerif';
  font-style: normal;
  font-weight: 600;
  src: url("/assets/font/NotoSerifJP-SemiBold.woff2") format("woff2"), url("/assets/font/NotoSerifJP-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'PressStart2P';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/PressStart2P-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBlack';
  font-style: normal;
  font-weight: 900;
  src: url("/assets/font/NotoSansJP-Black.woff2") format("woff2"), url("/assets/font/NotoSansJP-Black.woff") format("woff"); }

@font-face {
  font-family: 'NotoSerifMedium';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/font/NotoSerifJP-Medium.woff2") format("woff2"), url("/assets/font/NotoSerifJP-Medium.woff") format("woff"); }

@media screen and (min-width: 769px) {
  .sp {
    display: none; } }

@media screen and (max-width: 768px) {
  .pc {
    display: none; } }

body {
  width: 100%;
  min-width: 1000px; }
  @media screen and (max-width: 768px) {
    body {
      min-width: 100%; } }

div#wrapper {
  min-width: 1000px;
  position: relative; }
  @media screen and (max-width: 768px) {
    div#wrapper {
      min-width: 100%;
      overflow: hidden; } }

/*****************************************************************************************************************
 * パンくずリスト
 *****************************************************************************************************************/
